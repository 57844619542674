.Scrolltop {
  position: fixed;
  right: 39px;
  bottom: 107px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-weight: 100;
  font-size: 16px;
  background-color: $theme-SkinColor;
  color: #fff;
  z-index: 1;
  text-align: center;
  border-radius: 50%;
  transition: all 0.5s ease-in-out 0s;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  cursor: pointer;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

// key frames
.bounce {
  animation-name: bounce;
  animation-duration: 4s;
  -moz-animation-duration: 4s;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
